import './App.css';
import QAChat from './_components/QAChat';

function App() {
  return (
    <div className="App">
      <QAChat />
    </div>
  );
}

export default App;
