import React, { useState, useEffect } from 'react';
import { Send } from 'lucide-react';
import styles from "./index.module.css";

function scaleScore(score) {
  // Ensure the input score is within the range 0-5
  const clampedScore = Math.min(Math.max(score, 0), 5);

  // Scale the score from 0-5 to 0-100
  const scaledScore = (clampedScore / 5) * 100;

  // Round the result to two decimal places
  return Math.round(scaledScore * 100) / 100;
}

const QAChat = () => {
  const [QA, setQA] = useState({
    idx: 0,
    questions: [],
    messages: [],
    messageType: 'answer'
  })
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchInitialPosts();
  }, []);

  const fetchInitialPosts = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch('https://fact-check.monetanalytics.com/monet-ai/questions/');
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }
      const data = await response.json();
      const questions = data?.questions ?? [];
      setQA(qa => ({ ...qa, questions, currentQuestion: questions[qa.idx], messages: [{ type: 'question', content: questions[qa.idx] }] }));
      // setMessages(data.map(post => ({
      //   type: post.isQuestion ? 'question' : 'answer',
      //   content: post.content
      // })));
    } catch (err) {
      setError('Failed to load messages. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;
    setInput('');

    if (QA.messageType === 'question') {
      setQA(qa => {
        const newState = { ...qa };
        if (qa.idx >= newState.length) {
          newState.questions.push(input);
          newState.idx = newState.questions.length - 1;
        }
        else
          newState.questions[qa.idx] = input;
        newState.currentQuestion = input;
        if (newState.messages[newState.messages.length - 1].type === 'question')
          newState.messages.pop();
        newState.messages.push({ type: qa.messageType, content: input });
        return newState;
      });
      return
    }

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch('https://fact-check.monetanalytics.com/monet-ai/get_monet_relevance/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: QA.questions[QA.idx], answer: input }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch answer');
      }
      const data = await response.json();
      const newResponse = { type: 'answer', content: input };
      const apiResponse = { score: data.score ? scaleScore(data.score) : 0, explanation: data.explanation ?? "there seems to be an issue with response" };
      /**
       {"explanation":"The user's answer is not relevant to the behavioral question asked. The question asks for a description of a challenging project and how it was handled, but the user provided a response related to watching a video and their opinion about it. As a result, the score for this response is 0 since it does not meet the requirements of the behavioral question.",
       "score":0}
       */
      // const apiResponse = response.error ? { score: -1, explaination: "" } : { score: response.score, explaination: response.explaination }
      setQA(qa => {
        ++qa.idx;
        return {
          ...qa, messages: [...qa.messages,
            newResponse,
          { type: "answer", special: true, content: `Your answer is ${apiResponse.score} % relevant` },
          { type: "answer", special: true, content: `Explaination: ${apiResponse.explanation}` },
          { type: 'question', content: qa.questions[qa.idx] }
          ]
        }
      });
      // setMessages(prevMessages => [...prevMessages, { type: 'answer', content: data.answer }]);
    } catch (err) {
      setError('Failed to get an answer. Please try again.');
      // setMessages(prevMessages => prevMessages.slice(0, -1)); // Remove the question if we couldn't get an answer
      setQA(qa => ({ ...qa, message: qa.messages.slice(0, -1) })); // Remove the question if we couldn't get an answer
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-screen max-w-full w-3/4 mx-auto p-4">
      {error && <div className={styles.error}>{error}</div>}
      <div className="flex-1 overflow-y-auto mb-4 space-y-4">
        {QA.messages.map((message, index) => (
          <div key={index} className={`flex ${message.type === 'question' ? 'justify-end' : 'justify-start'}`}>
            <div className={`max-w-xs lg:max-w-md p-4 rounded-lg ${message.type === 'question' ? 'bg-blue-500 text-white' : 'bg-cyan-600 text-gray-800'
              }`}>
              <p className="font-bold mb-1">{message.type === 'question' ? 'Question' : message.special ? 'AI Response' : 'Answer'}</p>
              {message.content}
            </div>
          </div>
        ))}
        {isLoading && <div className={styles.loading}>Loading...</div>}
      </div>
      <form onSubmit={handleSubmit} className="flex">
        <select
          value={QA.messageType}
          onChange={(e) => setQA(qa => ({ ...qa, messageType: e.target.value }))}
          className={styles.select}
        >
          <option value="question">Question</option>
          <option value="answer">Answer</option>
        </select>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your response..."
          className="flex-1 p-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          disabled={isLoading}
        />
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <Send size={24} />
        </button>
      </form>
    </div>
  );
};

export default QAChat;